import { FC } from 'react';

import NewLogo from 'assets/images/new_sandtoft_logo.png';

type Props = {};

const Logo: FC<Props> = (props) => {
  return (
    <div data-testid="Logo" className="">
      <img src={NewLogo} alt="sandtoft" width="300px" />
    </div>
  );
};

Logo.propTypes = {};

export default Logo;
